import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserDetails } from '../../types/UserDetails';
import { getGroupNameFromLocalStore } from '../../utils/GroupDropDownHelper';
import { AIGradingAccess } from '../../types/AIGradingAccess';
import { List, UserList } from '../../types/List';

export interface UserState {
    userId: string | undefined;
    userCapabilities: string[];
    userDetails: UserDetails | undefined;
    userToken: string | undefined;
    hasFleetAccess: boolean
    selectedGroupName: string;
    aiGradingAccess: AIGradingAccess;
    aiGradingActive: boolean;
    capabilitiesLoaded: boolean;
    userLists: UserList[];
    lists: List[];
    listVessels: number[];
    selectedList: string;
    loadingList: boolean;
}

export const initialState: UserState = {
    userId: undefined,
    userCapabilities: [],
    userDetails: undefined,
    userToken: undefined,
    hasFleetAccess: false,
    selectedGroupName: getGroupNameFromLocalStore(),
    aiGradingAccess: 0,
    aiGradingActive: false,
    capabilitiesLoaded: false,
    userLists: [],
    lists: [],
    listVessels: [],
    selectedList: "",
    loadingList: false,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserId: (state, action: PayloadAction<string | undefined>) => {
            state.userId = action.payload;
        },
        setUserCapabilities: (state, action: PayloadAction<string[]>) => {
            state.userCapabilities = action.payload;
            state.capabilitiesLoaded = true;
        },
        setUserDetails: (state, action: PayloadAction<UserDetails | undefined>) => {
            state.userDetails = action.payload;
        },
        setUserToken: (state, action: PayloadAction<string | undefined>) => {
            state.userToken = action.payload;
        },
        setHasFleetAccess: (state, action: PayloadAction<boolean>) => {
            state.hasFleetAccess = action.payload;
        },
        setSelectedGroupName: (state, action: PayloadAction<string>) => {
            state.selectedGroupName = action.payload;
        },
        setAiGradingAccess: (state, action: PayloadAction<AIGradingAccess>) => {
            state.aiGradingAccess = action.payload;
        },
        setAiGradingActive: (state, action: PayloadAction<boolean>) => {
            state.aiGradingActive = action.payload;
        },
        setUserLists: (state, action: PayloadAction<UserList[]>) => {
            state.userLists = action.payload;
        },
        setLists: (state, action: PayloadAction<List[]>) => {
            state.lists = action.payload;
        },
        setListVessels: (state, action: PayloadAction<number[]>) => {
            state.listVessels = action.payload;
        },
        setSelectedList: (state, action: PayloadAction<string>) => {
            state.selectedList = action.payload;
        },
        setLoadingList: (state, action: PayloadAction<boolean>) => {
            state.loadingList = action.payload;
        },
    },
});

export const { 
    setUserId,
    setUserCapabilities,
    setUserDetails,
    setUserToken,
    setHasFleetAccess,
    setSelectedGroupName,
    setAiGradingAccess,
    setAiGradingActive,
    setUserLists,
    setLists,
    setListVessels,
    setSelectedList,
    setLoadingList,
} = userSlice.actions;

export default userSlice.reducer;
