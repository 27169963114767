import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdvancedFilterSelectionState, BackgroundFilters, ForegroundFilters } from '../../types/AdvancedFilters';
import { defaultBackgroundState, defaultForegroundState } from '../../utils/AdvancedFilterHelper';
import { getTranslationObject } from '../../services/Translation';

const t = getTranslationObject();

const initialSelectionState: AdvancedFilterSelectionState = {
    foreground: defaultForegroundState,
    background: [defaultBackgroundState, defaultBackgroundState],
    marketDataToggleSelected: true
}

export interface AdvancedFiltersState {
    open: boolean,
    selectedSection: string,
    filterState: AdvancedFilterSelectionState,
    appliedFilters: AdvancedFilterSelectionState;
    showWarningMessage: boolean;
    loading: boolean;
    totalForegroundFilterCount: number,
    totalBackgroundFilterCount: number,  
}

export const initialState: AdvancedFiltersState = {
    open: false,
    selectedSection: "",
    filterState: initialSelectionState,
    appliedFilters: initialSelectionState,
    showWarningMessage: false,
    loading: false,
    totalForegroundFilterCount: 0,
    totalBackgroundFilterCount: 0,
};

export const advancedFiltersSlice = createSlice({
    name: 'advancedFilters',
    initialState,
    reducers: {
        setOpen: (state, action: PayloadAction<boolean>) => {
            state.open = action.payload;
        },
        setSelectedSection: (state, action: PayloadAction<string>) => {
            state.selectedSection = action.payload;
        },
        setFilterState: (state, action: PayloadAction<AdvancedFilterSelectionState>) => {
            state.filterState = action.payload;
        },
        setAppliedFilters: (state, action: PayloadAction<AdvancedFilterSelectionState>) => {
            state.appliedFilters = action.payload;
        },
        setShowWarningMessage: (state, action: PayloadAction<boolean>) => {
            state.showWarningMessage = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setTotalForegroundFilterCount: (state, action: PayloadAction<number>) => {
            state.totalForegroundFilterCount = action.payload;
        },
        setTotalBackgroundFilterCount: (state, action: PayloadAction<number>) => {
            state.totalBackgroundFilterCount = action.payload;
        },
        setForegroundFilterState: (state, action: PayloadAction<ForegroundFilters>) => {
            state.filterState = {
                ...state.filterState,
                foreground: action.payload,
            }
        },
        setBackgroundFilterState: (state, action: PayloadAction<[BackgroundFilters, BackgroundFilters]>) => {            
            state.filterState = {
                ...state.filterState,
                background: action.payload,
            }
        },
        resetAdvancedFilters: (state) => {
            state.open = false;
            state.selectedSection = "";
            state.filterState = initialSelectionState;
            state.appliedFilters = initialSelectionState;
            state.showWarningMessage = false;
            state.loading = false;
            state.totalForegroundFilterCount = 0;
            state.totalBackgroundFilterCount = 0;
        }
    },
});

export const {
    setOpen,
    setSelectedSection,
    setFilterState,
    setAppliedFilters,
    setShowWarningMessage,
    setLoading,
    setTotalForegroundFilterCount,
    setTotalBackgroundFilterCount,
    setForegroundFilterState,
    setBackgroundFilterState,
    resetAdvancedFilters,
} = advancedFiltersSlice.actions;

export default advancedFiltersSlice.reducer;
