import { dataRequest, postRequest } from "./DataRequest";
import { VesselSubgradeResponse } from "../types/VesselSubgradeResponse";
import { SubgradeBackingData } from "../types/SubgradeBackingData";
import { GlobalFilterValue } from "../types/GlobalFilterValue";
import { BackgroundFilters } from "../types/AdvancedFilters";
import { getMinTeuRange } from "../utils/advancedFilter/helpers";

export const getSubgradeData = async (vessels: number[]): Promise<VesselSubgradeResponse> => {
    const queryString = vessels.length ? `?vessels=${vessels.join(",")}`: "";
    const request: any = await dataRequest(`/Fleet/subgrades${queryString}`);

    return Promise.resolve(request);
}

export const getBackgroundSubgradeData = async (advancedFilters: BackgroundFilters | undefined, filters: GlobalFilterValue | undefined, inspectionDateRange: Date[] | undefined): Promise<SubgradeBackingData> => {
    const body = {
        "country_of_build": advancedFilters?.countryOfBuild.data ?? [],
        "vessel_types": filters?.vesselTypes ?? [],
        "vessel_ages": filters?.vesselAgeBands ?? [],
        "inspection_date_range": inspectionDateRange ?? [],
        "dwt": advancedFilters?.dwt.changed 
            ? advancedFilters?.dwt.data
            : [],
        "teu": advancedFilters?.teu.changed
            ? [getMinTeuRange(advancedFilters.teu), advancedFilters?.teu.data[1]]
            : [],
    }

    const request: any = await postRequest("/Fleet/backing/subgrades", body);

    return Promise.resolve(request.data);
}
