import {MarketingPage} from "@idwal/idwal-react-components";
import {useLocation} from "react-router-dom";
import {DEFECTS, GRADE, PSC, SUBGRADES, SCREENING} from "../types/RouteNames";
import {sendGAEvent} from "../AnalyticsService";
import {
    DEFECTS_LEARN_MORE_CLICK,
    DEFECTS_REQUEST_DEMO_CLICK,
    GRADES_LEARN_MORE_CLICK,
    GRADES_REQUEST_DEMO_CLICK,
    MARKETING_PAGE_CATEGORY,
    MY_FLEET_LEARN_MORE_CLICK,
    MY_FLEET_REQUEST_DEMO_CLICK,
    PSC_LEARN_MORE_CLICK,
    PSC_REQUEST_DEMO_CLICK,
    SCREENING_LEARN_MORE_CLICK,
    SCREENING_REQUEST_DEMO_CLICK,
    SUBGRADES_LEARN_MORE_CLICK,
    SUBGRADES_REQUEST_DEMO_CLICK
} from "../constants/analytics";

const Unauthorised = () => {
    const location = useLocation();

    let marketingPage;

    switch (location.pathname) {
        case "/":
            marketingPage = <MarketingPage type={"myFleet"}
                                           learnMoreCallback={() => sendGAEvent(MARKETING_PAGE_CATEGORY, MY_FLEET_LEARN_MORE_CLICK)}
                                           requestDemoCallback={() => sendGAEvent(MARKETING_PAGE_CATEGORY, MY_FLEET_REQUEST_DEMO_CLICK)}
                                           showLogo={true}
            />;
            break;
        case `/${GRADE}`:
            marketingPage = <MarketingPage type={"grade"}
                                           learnMoreCallback={() => sendGAEvent(MARKETING_PAGE_CATEGORY, GRADES_LEARN_MORE_CLICK)}
                                           requestDemoCallback={() => sendGAEvent(MARKETING_PAGE_CATEGORY, GRADES_REQUEST_DEMO_CLICK)}
                                           showLogo={true}
            />;
            break;
        case `/${SUBGRADES}`:
            marketingPage = <MarketingPage type={"subgrade"}
                                           learnMoreCallback={() => sendGAEvent(MARKETING_PAGE_CATEGORY, SUBGRADES_LEARN_MORE_CLICK)}
                                           requestDemoCallback={() => sendGAEvent(MARKETING_PAGE_CATEGORY, SUBGRADES_REQUEST_DEMO_CLICK)}
                                           showLogo={true}
            />;
            break;
        case `/${DEFECTS}`:
            marketingPage = <MarketingPage type={"defects"}
                                           learnMoreCallback={() => sendGAEvent(MARKETING_PAGE_CATEGORY, DEFECTS_LEARN_MORE_CLICK)}
                                           requestDemoCallback={() => sendGAEvent(MARKETING_PAGE_CATEGORY, DEFECTS_REQUEST_DEMO_CLICK)}
                                           showLogo={true}
            />;
            break;
        case `/${PSC}`:
            marketingPage = <MarketingPage type={"psc"}
                                           learnMoreCallback={() => sendGAEvent(MARKETING_PAGE_CATEGORY, PSC_LEARN_MORE_CLICK)}
                                           requestDemoCallback={() => sendGAEvent(MARKETING_PAGE_CATEGORY, PSC_REQUEST_DEMO_CLICK)}
                                           showLogo={true}
            />;
            break;
        case `/${SCREENING}`:
            marketingPage = <MarketingPage type={"screening"}
                                            learnMoreCallback={() => sendGAEvent(MARKETING_PAGE_CATEGORY, SCREENING_LEARN_MORE_CLICK)}
                                            requestDemoCallback={() => sendGAEvent(MARKETING_PAGE_CATEGORY, SCREENING_REQUEST_DEMO_CLICK)}
                                            showLogo={true}
            />;
            break;
        default:
            marketingPage = <MarketingPage type={"myFleet"}
                                           learnMoreCallback={() => sendGAEvent(MARKETING_PAGE_CATEGORY, MY_FLEET_LEARN_MORE_CLICK)}
                                           requestDemoCallback={() => sendGAEvent(MARKETING_PAGE_CATEGORY, MY_FLEET_REQUEST_DEMO_CLICK)}
                                           showLogo={true}
            />;
            break;
    }

    return (
        <div className="h-full flex flex-column align-items-center justify-content-center">
            <div id={"marketing-container"}>
                {marketingPage}
            </div>
        </div>
    )
}

export default Unauthorised;
