import { AppThunk } from "../store";
import {
    fetchAgeOfOpenDefects,
    fetchClosedRatios,
    fetchCumulativeDefects
} from "./defectInsightsThunks";
import { fetchDefects } from "./defectThunks";
import {
    fetchFleetGraph,
    fetchSubgradeBackingData,
    fetchSubgradeData
} from "./fleetGraphThunks";
import {
    fetchFleetData,
    fetchInspectionAndGradeData,
    setupFleetData,
    setupFleetStatsData
} from "./fleetThunks";
import { fetchFleetDataV2 } from "./fleetThunksV2";
import { fetchNotifications } from "./notificationsThunks";
import {
    fetchPreviousRecipients,
    fetchSharedDefects
} from "./sharedDefectsThunks";

export const fetchOrder: Record<string, AppThunk[][]> = {
    "": [
        [
            fetchFleetData(),
            fetchInspectionAndGradeData(),
            fetchDefects(),
            fetchSubgradeData(),
            fetchSubgradeBackingData()
        ],
        [
            setupFleetData(),
            fetchPreviousRecipients(),
            fetchFleetGraph(),
            setupFleetStatsData()
        ]
    ],
    grade: [
        [fetchFleetData(), fetchInspectionAndGradeData()],
        [setupFleetData(), fetchFleetGraph()],
        [
            fetchDefects(),
            fetchSubgradeData(),
            fetchSubgradeBackingData(),
            fetchPreviousRecipients()
        ],
        [setupFleetStatsData()]
    ],
    subgrade: [
        [fetchFleetData(), fetchInspectionAndGradeData(), fetchSubgradeData()],
        [setupFleetData(), fetchSubgradeBackingData()],
        [fetchDefects(), fetchFleetGraph(), fetchPreviousRecipients()],
        [setupFleetStatsData()]
    ],
    defects: [
        [fetchFleetData(), fetchInspectionAndGradeData(), fetchDefects()],
        [fetchSubgradeData(), fetchSubgradeBackingData()],
        [
            setupFleetData(),
            fetchPreviousRecipients(),
            fetchSubgradeData(),
            fetchSubgradeBackingData(),
            fetchFleetGraph()
        ],
        [setupFleetStatsData()]
    ],
    techManager: [[fetchSharedDefects()], [fetchNotifications()]]
};

export const fetchOrderV2: Record<string, AppThunk[][]> = {
    "": [
        [fetchFleetDataV2()],
        [fetchInspectionAndGradeData(true)],
        [
            fetchDefects(true),
            fetchSubgradeData(true),
            fetchSubgradeBackingData()
        ],
        [setupFleetData(), fetchPreviousRecipients(), fetchFleetGraph(), setupFleetStatsData()]
    ],
    grade: [
        [fetchFleetDataV2()],
        [fetchInspectionAndGradeData(true)],
        [fetchFleetGraph()],
        [
            fetchDefects(true),
            fetchSubgradeData(true),
            fetchSubgradeBackingData()
        ],
        [setupFleetData(), fetchPreviousRecipients(), setupFleetStatsData()]
    ],
    subgrade: [
        [fetchFleetDataV2()],
        [fetchInspectionAndGradeData(true)],
        [fetchSubgradeData(true), fetchSubgradeBackingData()],
        [fetchDefects(true)],
        [setupFleetData(), fetchFleetGraph(), fetchPreviousRecipients(), setupFleetStatsData()]
    ],
    defects: [
        [fetchFleetDataV2()],
        [fetchInspectionAndGradeData(true)],
        [fetchDefects(true)],
        [
            fetchSubgradeData(true),
            fetchSubgradeBackingData()
        ],
        [setupFleetData(), fetchPreviousRecipients(), fetchFleetGraph(), setupFleetStatsData()]
    ],
    techManager: [[fetchSharedDefects()], [fetchNotifications()]]
};

export const fetchOrderByList: Record<string, AppThunk[][]> = {
    "": [
        [fetchFleetDataV2()],
        [fetchInspectionAndGradeData(true)],
        [
            fetchDefects(true),
            fetchSubgradeData(true),
            fetchSubgradeBackingData()
        ],
        [
            setupFleetData(),
            fetchFleetGraph(),
            setupFleetStatsData(),
            fetchClosedRatios(),
            fetchCumulativeDefects(),
            fetchAgeOfOpenDefects()
        ]
    ],
    grade: [
        [fetchFleetDataV2()],
        [fetchInspectionAndGradeData(true)],
        [fetchFleetGraph()],
        [
            fetchDefects(true),
            fetchSubgradeData(true),
            fetchSubgradeBackingData()
        ],
        [
            setupFleetData(),
            fetchPreviousRecipients(),
            setupFleetStatsData(),
            fetchClosedRatios(),
            fetchCumulativeDefects(),
            fetchAgeOfOpenDefects()
        ]
    ],
    subgrade: [
        [fetchFleetDataV2()],
        [fetchInspectionAndGradeData(true)],
        [
            fetchDefects(true),
            fetchSubgradeData(true),
            fetchSubgradeBackingData()
        ],
        [
            setupFleetData(),
            fetchFleetGraph(),
            fetchPreviousRecipients(),
            setupFleetStatsData(),
            fetchClosedRatios(),
            fetchCumulativeDefects(),
            fetchAgeOfOpenDefects()
        ]
    ],
    defects: [
        [fetchFleetDataV2()],
        [fetchInspectionAndGradeData(true)],
        [fetchDefects(true)],
        [
            setupFleetData(),
            fetchSubgradeData(true),
            fetchSubgradeBackingData(),
            fetchPreviousRecipients()
        ],
        [
            fetchFleetGraph(),
            setupFleetStatsData(),
            fetchClosedRatios(),
            fetchCumulativeDefects(),
            fetchAgeOfOpenDefects()
        ]
    ]
};
