import { getFleetTokenV2 } from "../../services/FleetDataService";
import {
    setFleetDataItems,
    setError,
    setLoading,
    setData,
    setStatTileConfigs
} from "../slices/fleetSlice";
import {
    setLists,
    setListVessels,
    setLoadingList,
    setSelectedGroupName,
    setSelectedList,
    setUserDetails,
    setUserLists
} from "../slices/userSlice";
import { AppThunk } from "../store";
import {
    addCustomColumnData,
    addSpecialColumnData,
    decodeFleetToken
} from "../../utils/UserHelper";
import { getListData, getUserDetailsV2 } from "../../services/UserService";
import {
    selectListVessels,
    selectSelectedGroupName,
    selectUserDetails,
    selectUserLists
} from "../selectors/userSelectors";
import { getShipDetailsV2 } from "../../services/ShipServiceV2";
import { GroupDetails } from "../../types/GroupDetails";
import { fetchDataByPath } from "./initThunks";
import { getDefaultGroupName } from "../../utils/GroupDropDownHelper";
import { clearAllFilters } from "./filtersThunks";
import {
    setLoading as setDefectsLoading,
    setSelectedVessel,
    setFilteredDefects
} from "../slices/defectSlice";
import {
    setLoading as setFleetGraphLoading,
    setSubgradeLoading
} from "../slices/fleetGraphSlice";
import { GROUP_DROP_DOWN_ALL_CODE } from "../../utils/Constants";
import { FleetDataItem } from "../../types/FleetDataItem";

export const fetchFleetDataV2 =
    (): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
        dispatch(setLoading(true));
        dispatch(setError(false));

        try {
            const userLists = selectUserLists(getState());

            if (!userLists.length) {
                await dispatch(fetchFleetTokenV2());
            }

            await dispatch(fetchUserDetailsV2());

            await dispatch(fetchShipDetailsV2());

            dispatch(setLoading(false));
        } catch (e) {
            console.error(e);
            dispatch(setError(true));
        }

        return true;
    };

export const fetchFleetTokenV2 =
    (): AppThunk<Promise<boolean>> => async (dispatch) => {
        try {
            const response = await getFleetTokenV2();

            if (response) {
                const userLists = decodeFleetToken(response);

                dispatch(setUserLists(userLists));
            } else {
                throw Error("no data");
            }
        } catch (e) {
            console.error(e);
        }

        return true;
    };

export const fetchUserDetailsV2 =
    (): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
        try {
            const state = getState();
            const userDetails = selectUserDetails(state);

            if (!userDetails) {
                const response = await getUserDetailsV2();

                if (response) {
                    dispatch(setUserDetails(response));

                    const { groupDetails } = response;

                    let selectedGroupName = getDefaultGroupName(groupDetails);

                    if (selectedGroupName === GROUP_DROP_DOWN_ALL_CODE) {
                        selectedGroupName = Object.keys(groupDetails)[0];
                    }

                    dispatch(setSelectedGroupName(selectedGroupName));
                    dispatch(selectUserList(selectedGroupName, true));
                } else {
                    throw Error("no data");
                }
            }
        } catch (e) {
            console.error(e);
        }

        return true;
    };

export const fetchShipDetailsV2 =
    (): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
        const state = getState();
        const vessels = selectListVessels(state);
        const userDetails = selectUserDetails(state);
        const selectedGroupName = selectSelectedGroupName(state);

        try {
            const response = await getShipDetailsV2(vessels);

            if (response) {
                const fleetDataItems: FleetDataItem[] = response.map(
                    (ship: any) => ({
                        vessel: {
                            imo: `${ship.imo}`,
                            vesselName: ship.vessel_name,
                            type: ship.vessel_type,
                            // subtype: ship.shiptype_level_2,
                            technicalManager: ship.technical_manager,
                            classSociety: ship.classification_society,
                            flag: ship.flag_state,
                            dwt: ship.deadweight,
                            buildYear: ship.year_of_build,
                            shipAge: Number(ship.ship_age),
                            countryOfBuild: ship.build_country,
                            teu: Number(ship.teu),
                            riskLevel: 0,
                            notes: "",
                            group: "",
                            specialSurveyDate: undefined
                        },
                        inspection: {}
                    })
                );

                if (userDetails?.groupDetails) {
                    const groupValues: GroupDetails[] = Object.values(
                        userDetails.groupDetails
                    );

                    const fleetDataItemsWithColumnData = fleetDataItems.map(
                        (item) => {
                            const fleetDataItem = { ...item };
                            const vessel = { ...fleetDataItem.vessel };
                            vessel.group = selectedGroupName;

                            groupValues.forEach((group) => {
                                addSpecialColumnData(group, vessel);
                                addCustomColumnData(
                                    group,
                                    fleetDataItem,
                                    vessel
                                );
                            });

                            return {
                                ...fleetDataItem,
                                vessel
                            };
                        }
                    );

                    // Can remove setData once V2 flag is removed
                    dispatch(
                        setData({
                            fleetDataItems: fleetDataItemsWithColumnData,
                            userDetails
                        })
                    );
                    dispatch(setFleetDataItems(fleetDataItemsWithColumnData));
                }
            } else {
                throw Error("no data");
            }
        } catch (e) {
            console.error(e);
        }

        return true;
    };

export const selectUserList =
    (groupName: string, initial?: boolean): AppThunk =>
    async (dispatch, getState) => {
        try {
            const userLists = selectUserLists(getState());

            let userList = userLists.find(
                (userList) => userList.group.name === groupName
            );

            if (!userList) {
                userList = userLists[0];
            }

            if (userList) {
                const { default_list, lists, vessels } = userList;

                const sortedLists = [...lists].sort((a) =>
                    a.id === default_list ? 0 : 1
                );
                dispatch(setLists(sortedLists));
                dispatch(setListVessels(vessels));
                dispatch(setSelectedList(default_list));

                if (!initial) {
                    dispatch(selectList(default_list));
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

export const selectList =
    (list: string): AppThunk =>
    async (dispatch, getState) => {
        dispatch(setLoadingList(true));

        try {
            const state = getState();
            const selectedGroupName = selectSelectedGroupName(state);
            const userLists = selectUserLists(state);

            let userList = userLists.find(
                (userList) => userList.group.name === selectedGroupName
            );

            if (!userList) {
                userList = userLists[0];
            }

            if (userList) {
                dispatch(setSelectedList(list));
                dispatch(setStatTileConfigs(undefined));
                dispatch(clearAllFilters());
                dispatch(setSelectedVessel(""));
                dispatch(setLoading(true));
                dispatch(setFleetGraphLoading(true));
                dispatch(setSubgradeLoading(true));
                dispatch(setDefectsLoading(true));
                dispatch(setFilteredDefects([]));

                const listData = await getListData(userList.group.id, list);

                const listVessels = listData.results[0].vessels;

                dispatch(setListVessels(listVessels));

                dispatch(fetchDataByPath(false, false, true));
            }
        } catch (e) {
            console.error(e);
        }

        dispatch(setLoadingList(false));
    };
