import { getUserCapabilities, isIdUser } from "../../services/UserService";
import { Capability } from "../../types/Capability";
import { selectUserId } from "../selectors/userSelectors";
import {
    setHasFleetAccess,
    setUserCapabilities,
    setUserId
} from "../slices/userSlice";
import { AppThunk } from "../store";
import { AmplifyUser } from "@aws-amplify/ui";
import { fetchOrder, fetchOrderByList, fetchOrderV2 } from "./fetchOrder";
import { fetchNotifications } from "./notificationsThunks";
import { fetchScreeningData } from "./screeningThunks";
import { fetchTokenData } from "./tokenThunks";

export const initFetchData =
    (user: AmplifyUser, releaseMultiLists: boolean): AppThunk =>
    async (dispatch, getState) => {
        const userId = selectUserId(getState());

        if (user && !userId) {
            dispatch(setUserId(user.username));

            const userCapabilities = await getUserCapabilities(user.username);

            dispatch(setUserCapabilities(userCapabilities));
            dispatch(setHasFleetAccess(isIdUser(userCapabilities)));

            const isTechManager = userCapabilities.includes(
                Capability.CAP_FLEETAPP_VIEW_RECTIFICATIONS
            );

            dispatch(fetchDataByPath(isTechManager, releaseMultiLists, false));
            dispatch(fetchIndependentData());
        }
    };

export const fetchDataByPath =
    (
        techManager: boolean,
        releaseMultiLists: boolean,
        byList: boolean
    ): AppThunk =>
    async (dispatch) => {
        let path = window.location.href.split("/")[1] ?? "";

        if (techManager) {
            path = "techManager";
        }

        if (path === "psc" || path === "screening") {
            path = "";
        }

        let fetchOrderByPath = fetchOrder[path];

        if (releaseMultiLists) {
            fetchOrderByPath = fetchOrderV2[path];
        }

        if (byList) {
            fetchOrderByPath = fetchOrderByList[path];
        }

        for (const appThunks of fetchOrderByPath) {
            const promises = appThunks.map((appThunk) => dispatch(appThunk));
            await Promise.all(promises);
        }
    };

export const fetchIndependentData = (): AppThunk => async (dispatch) => {
    dispatch(fetchNotifications());
    dispatch(fetchScreeningData());
    dispatch(fetchTokenData());
};
