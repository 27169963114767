import { getFleetToken } from "../../services/FleetDataService";
import { setUserToken } from "../slices/userSlice";
import { AppThunk } from "../store";
import { fetchAgeOfOpenDefects, fetchClosedRatios, fetchCumulativeDefects } from "./defectInsightsThunks";
import { fetchPSCDeficiencies, fetchPSCInspections, setupPSCVesselData } from "./pscThunks";

export const fetchTokenData = (): AppThunk => async (dispatch) => {
    try {
        const userToken = await getFleetToken();

        if (userToken) {
            dispatch(setUserToken(userToken));

            const pscPromoises = [
                dispatch(fetchPSCInspections()),
                dispatch(fetchPSCDeficiencies())
            ];

            await Promise.all(pscPromoises);

            dispatch(setupPSCVesselData());

            dispatch(fetchClosedRatios());
            dispatch(fetchCumulativeDefects());
            dispatch(fetchAgeOfOpenDefects());
        }
    } catch (e) {
        console.error(e);
    }
};