import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/storeHooks";
import {
    selectLists,
    selectLoadingList,
    selectSelectedList
} from "../../store/selectors/userSelectors";
import { SelectButton, SelectButtonChangeEvent } from "primereact/selectbutton";
import { selectList } from "../../store/thunks/fleetThunksV2";
import "./ListPicker.css";
import { classNames } from "primereact/utils";
import { useFlags } from "launchdarkly-react-client-sdk";

export const ListPicker = () => {
    const dispatch = useAppDispatch();

    const { releaseMultiLists } = useFlags();

    const lists = useAppSelector(selectLists);
    const selected = useAppSelector(selectSelectedList);
    const loading = useAppSelector(selectLoadingList);

    const options = useMemo(
        () => lists.map((list) => ({ label: list.label, value: list.id })),
        [lists]
    );

    const onChange = useCallback(
        (event: SelectButtonChangeEvent) => {
            if (event.value && event.value !== selected) {
                dispatch(selectList(event.value));
            }
        },
        [selected]
    );    

    if (!releaseMultiLists) {
        return null;
    }

    return (
        <SelectButton
            value={selected}
            onChange={onChange}
            optionLabel="label"
            options={options}
            disabled={loading}
            className={classNames("list-picker", "ml-4", {
                "list-picker-loading": loading
            })}
        />
    );
};
