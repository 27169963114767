import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MarketDataFilteringOptions } from '../../types/MarketDataFilteringOptions';
import { VesselTypeWithCount } from '../../types/VesselTypeWithCount';
import { InspectionAndGradeDataResponse } from '../../types/InspectionAndGradeDataResponse';
import { FleetDataResponse } from '../../types/FleetDataResponse';
import { FleetDataItem } from '../../types/FleetDataItem';
import { InternalGradeData } from '../../types/InternalGradeData';
import { StatTileConfig } from '../../types/StatTileConfig';

type RequestInspectionDialog = {
    show: boolean;
    vessels: FleetDataItem[];
    fromVesselView?: boolean;
}

export interface FleetState {
    data: FleetDataResponse;
    loading: boolean;
    error: boolean;
    fleetDataItems: FleetDataItem[]; 
    distinctVessels: string[];
    distinctVesselsWithCount: VesselTypeWithCount[];
    distinctVesselTypes: string[];
    distinctTechnicalManagers: string[];
    distinctCountriesOfBuild: string[];
    distinctFlags: string[];
    marketDataFilteringOptions: MarketDataFilteringOptions | undefined;
    statTileConfigs: StatTileConfig[] | undefined;
    statsTilesVisible: boolean;
    internalGradeData: InternalGradeData[] | undefined;
    inspectionAndGradeData: InspectionAndGradeDataResponse | undefined;
    inspectionAndGradeDataLoading: boolean;
    inspectionAndGradeDataError: boolean;
    requestInspectionDialog: RequestInspectionDialog;
    showStatsTilesPanel: boolean;
}

export const initialState: FleetState = {
    data: {
        fleetDataItems: [],
        userDetails: undefined,
    },
    loading: true,
    error: false,
    fleetDataItems: [],
    distinctVessels: [],
    distinctVesselsWithCount: [],
    distinctVesselTypes: [],
    distinctTechnicalManagers: [],
    distinctCountriesOfBuild: [],
    distinctFlags: [],
    marketDataFilteringOptions: undefined,
    statTileConfigs: undefined,
    statsTilesVisible: true,
    internalGradeData: undefined,
    inspectionAndGradeData: undefined,
    inspectionAndGradeDataLoading: true,
    inspectionAndGradeDataError: false,
    requestInspectionDialog: {
        show: false,
        vessels: [],
        fromVesselView: false,
    },
    showStatsTilesPanel: false,
};

export const fleetSlice = createSlice({
    name: 'fleet',
    initialState,
    reducers: {
        setData: (state, action: PayloadAction<FleetDataResponse>) => {
            state.data = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setError: (state, action: PayloadAction<boolean>) => {
            state.error = action.payload;
        },
        setFleetDataItems: (state, action: PayloadAction<FleetDataItem[]>) => {
            state.fleetDataItems = action.payload
        },
        updateFleetDataItem: (state, action: PayloadAction<FleetDataItem>) => {
            const imo = action.payload.vessel.imo;

            const updatedFleetDataItems = state.fleetDataItems.map((fleetDataItem) => {
                if (fleetDataItem.vessel.imo === imo) {
                    return action.payload;
                }

                return fleetDataItem;
            });

            state.fleetDataItems = updatedFleetDataItems
            state.data.fleetDataItems = updatedFleetDataItems;
        },
        setDistinctVessels: (state, action: PayloadAction<string[]>) => {
            state.distinctVessels = action.payload
        },
        setDistinctVesselsWithCount: (state, action: PayloadAction<VesselTypeWithCount[]>) => {
            state.distinctVesselsWithCount = action.payload
        },
        setDistinctVesselTypes: (state, action: PayloadAction<string[]>) => {
            state.distinctVesselTypes = action.payload
        },
        setDistinctTechnicalManagers: (state, action: PayloadAction<string[]>) => {
            state.distinctTechnicalManagers = action.payload
        },
        setDistinctCountriesOfBuild: (state, action: PayloadAction<string[]>) => {
            state.distinctCountriesOfBuild = action.payload
        },
        setDistinctFlags: (state, action: PayloadAction<string[]>) => {
            state.distinctFlags = action.payload
        },
        setMarketDataFilteringOptions: (state, action: PayloadAction<MarketDataFilteringOptions | undefined>) => {
            state.marketDataFilteringOptions = action.payload
        },
        setStatTileConfigs: (state, action: PayloadAction<StatTileConfig[] | undefined>) => {
            state.statTileConfigs = action.payload
        },
        setStatsTilesVisible: (state, action: PayloadAction<boolean>) => {
            state.statsTilesVisible = action.payload
        },
        setInspectionAndGradeData: (state, action: PayloadAction<InspectionAndGradeDataResponse | undefined>) => {
            state.inspectionAndGradeData = action.payload
        },    
        setInternalGradeData: (state, action: PayloadAction<InternalGradeData[] | undefined>) => {
            state.internalGradeData = action.payload
        },
        setInspectionAndGradeDataLoading: (state, action: PayloadAction<boolean>) => {
            state.inspectionAndGradeDataLoading = action.payload
        },
        setInspectionAndGradeDataError: (state, action: PayloadAction<boolean>) => {
            state.inspectionAndGradeDataError = action.payload
        },
        setRequestInspectionDialog: (state, action: PayloadAction<RequestInspectionDialog>) => {
            state.requestInspectionDialog = action.payload
        },
        setShowStatsTilesPanel: (state, action: PayloadAction<boolean>) => {
            state.showStatsTilesPanel = action.payload
        },
    },
});

export const { 
    setData,
    setLoading,
    setError,
    setFleetDataItems,
    updateFleetDataItem,
    setDistinctVessels,
    setDistinctVesselsWithCount,
    setDistinctVesselTypes,
    setDistinctTechnicalManagers,
    setDistinctCountriesOfBuild,
    setDistinctFlags,
    setMarketDataFilteringOptions,
    setStatTileConfigs,
    setStatsTilesVisible,
    setInspectionAndGradeData,
    setInternalGradeData,
    setInspectionAndGradeDataLoading,
    setInspectionAndGradeDataError,
    setRequestInspectionDialog,
    setShowStatsTilesPanel,
} = fleetSlice.actions;

export default fleetSlice.reducer;
