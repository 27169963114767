import { getFleetGraphData } from "../../services/FleetGraphDataService";
import {
    getBackgroundSubgradeData,
    getSubgradeData
} from "../../services/SubGradeDataService";
import { getTranslationObject } from "../../services/Translation";
import { getBackgroundFilterIndex } from "../../utils/advancedFilter/helpers";
import { getDateRangeFromAdvancedFilterInspectionDate } from "../../utils/AdvancedFilterHelper";
import {
    selectAppliedFilters,
    selectFitlerState
} from "../selectors/advancedFiltersSelectors";
import { selectGlobalFilters } from "../selectors/filtersSelectors";
import { selectListVessels } from "../selectors/userSelectors";
import {
    setData,
    setError,
    setLoading,
    setSubgradeBackingData,
    setSubgradeBackingError,
    setSubgradeBackingLoading,
    setSubgradeData,
    setSubgradeError,
    setSubgradeLoading
} from "../slices/fleetGraphSlice";
import { AppThunk } from "../store";

export const fetchFleetGraph =
    (): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
        const appliedFilters = selectAppliedFilters(getState());
        const filterState = selectFitlerState(getState());

        const t = getTranslationObject();

        const backgroundDataIndex = getBackgroundFilterIndex(
            appliedFilters.marketDataToggleSelected
        );
        const inspectionDateRange =
            getDateRangeFromAdvancedFilterInspectionDate(t, appliedFilters);

        dispatch(setLoading(true));
        dispatch(setError(false));

        try {
            const response = await getFleetGraphData(
                filterState.background[backgroundDataIndex],
                inspectionDateRange.dateRange
            );

            if (response?.message?.length >= 0) {
                dispatch(setData(response.message));
            } else {
                throw Error("no data");
            }
        } catch (e) {
            console.error(e);
            dispatch(setError(true));
        } finally {
            dispatch(setLoading(false));
        }

        return true;
    };

export const fetchSubgradeData =
    (releaseMultiLists?: boolean): AppThunk<Promise<boolean>> =>
    async (dispatch, getState) => {
        dispatch(setSubgradeLoading(true));
        dispatch(setSubgradeError(false));

        try {
            let vessels: number[] = [];

            if (releaseMultiLists) {
                vessels = selectListVessels(getState());
            }

            const response = await getSubgradeData(vessels);

            if (response) {
                dispatch(setSubgradeData(response));
            } else {
                throw Error("no data");
            }
        } catch (e) {
            console.error(e);
            dispatch(setSubgradeError(true));
        } finally {
            dispatch(setSubgradeLoading(false));
        }

        return true;
    };

export const fetchSubgradeBackingData =
    (): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
        const state = getState();
        const filterState = selectFitlerState(state);
        const globalFilters = selectGlobalFilters(state);

        const t = getTranslationObject();

        dispatch(setSubgradeBackingLoading(true));
        dispatch(setSubgradeBackingError(false));

        const filterIndex = getBackgroundFilterIndex(
            filterState.marketDataToggleSelected
        );
        const inspectionDates = getDateRangeFromAdvancedFilterInspectionDate(
            t,
            filterState
        );

        try {
            const response = await getBackgroundSubgradeData(
                filterState.background[filterIndex],
                globalFilters,
                inspectionDates.dateRange
            );
            if (response) {
                dispatch(setSubgradeBackingData(response));
            } else {
                throw Error("no data");
            }
        } catch (e) {
            console.error(e);
            dispatch(setSubgradeBackingError(true));
        } finally {
            dispatch(setSubgradeBackingLoading(false));
        }

        return true;
    };
